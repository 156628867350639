import axios from '@/utils/request'
import {API_BASE_URL} from '@/config/config'

//上传文件
export const UPLOAD_FILE = API_BASE_URL + 'file/upload-img'

/**
 *  普通用户登录注册
 */
// 手机号码登录注册
export const loginByPhone = params => axios.post("job/auth/loginByPhone", params);
// 发送验证码
export const sendVerificationCode = params => axios.post("job/auth/sendVerificationCode", params);
// 退出登录
export const logout = params => axios.get("job/auth/logout", {params});
// 获取用户信息
export const getUserInfo = params => axios.get("job/auth/getUserInfo", {params});
// 编辑用户信息
export const setUserInfo = params => axios.post("job/auth/setUserInfo", params);
// 通过ip地址获取用户所在地区
export const getUserRegionByIp = params => axios.get("job/auth/getUserRegionByIp", {params});


// 近期新办证情况
export const recentNewCertSituation = params => axios.post("DataScreen/recentNewCertSituation", params);
//零售户指标趋势
export const shopIndexTrend = params => axios.post("DataScreen/shopIndexTrend", params);
//
export const newCertApplyList = params => axios.post("DataScreen/newCertApplyList", params);

//获取定边县点聚合数据
export const getMarketUnitLocationList = params => axios.post("getMarketUnitLocationList", params);
// 市场单元规划明细
export const getMarketUnitPlanningDetail = params => axios.post("getMarketUnitPlanningDetail", params);
//获取市场单元数据概览
export const getDataOverview = params => axios.post("DataScreen/getDataOverview", params);
//发展排行榜
export const getDevelopRankingList = params => axios.post("DataScreen/getDevelopRankingList", params);
//轮候榜
export const getLicenceNewIntentionList = params => axios.post("DataScreen/getLicenceNewIntentionList", params);
/*预约轮候*/
// 获取市场单元
export const getWaitAppointmentMarketUnit = params => axios.get("DataScreen/getWaitAppointmentMarketUnit", {params});
// 获取市场单元预约池
export const getMarketUnitAppointmentPool = params => axios.get("DataScreen/getMarketUnitAppointmentPool", {params});
// 获取市场单元轮候池
export const getMarketUnitWaitingPool = params => axios.get("DataScreen/getMarketUnitWaitingPool", {params});
// 获取新办证预约轮候申请动态
export const getNewLicenseWaitAppointmentDynamic = params => axios.get("DataScreen/getNewLicenseWaitAppointmentDynamic", {params});
// 获取除定边县之外的所有店铺
export const getTownshipAllShop = params => axios.post("getTownshipAllShop", params);
//发送对话
export const sendChat = params => axios.post("businessTerminal/bdqf/sendChat", params);
//获取对话内容（轮询）
export const getChatData = params => axios.get("businessTerminal/bdqf/getChatData", {params});
//发送百度千帆AppBuilder大模型对话
export const sendAppBuilderModelChat = params => axios.post("businessTerminal/bdqf/sendAppBuilderModelChat", params);
//修改百度千帆AppBuilder知识库数据
export const generateKnowledgeBaseDocument = params => axios.post("admin/bdqf/generateKnowledgeBaseDocument", params);

// 网格发展程度占比
export const gridLevelDevelopmentProportion = params => axios.post("DataScreen/gridLevelDevelopmentProportion", params);
