import axios from 'axios'
import {API_BASE_URL} from '@/config/config.js'
import router from '@/router/index.js'
import {Auth} from '@/utils/auth.js'

export const source = axios.CancelToken.source()

const config = {
	headers: {
		'Content-Type': 'application/json;charset=UTF-8',
	},
	baseURL: API_BASE_URL,
	timeout: 0,
	responseType: 'json',
	maxContentLength: -1,
	withCredentials: true,
	crossDomain: true
}

const service = axios.create(config)

service.interceptors.request.use(function (res) {
	res.headers[Auth.request_key] = Auth.getToken();
	return res
})

service.interceptors.response.use(function (response) {
	// 重定向到登录页面
	if (response && response.data.hasOwnProperty("data") && response.data.data === 'no_login') {
		Auth.setToken('')
		router.replace({path: '/login'})
		return response
	} else {
		return response
	}
})

export default service
