//服务器地址
const HTTP_URL = 'https://jchelper.dindong.cn'
// const HTTP_URL_DEV = 'https://jchelper-dev.dindong.cn'
// const HTTP_URL_DEV = 'http://192.168.0.112:8000/'
const HTTP_URL_DEV = 'https://jchelper.zoomyoung.cn'

const API_BASE_URL = HTTP_URL_DEV + '/api/dingbian/' // 测试服
// const API_BASE_URL = HTTP_URL + '/v2/dingbian/'     // 正式服

export {
  HTTP_URL,
  API_BASE_URL,
}
