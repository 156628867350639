<template>
  <dv-full-screen-container class="main-wrapper">
    <main class="main-wrapper__content">
      <common-header />
      <section class="app-main">
        <router-view/>
      </section>
    </main>
  </dv-full-screen-container>
</template>

<script>
import dvFullScreenContainer from '@/components/dv-full-screen/dv-full-screen.vue'
import commonHeader from "./header/header"

export default {
	name: "layout",
	components: {dvFullScreenContainer, commonHeader},
  computed: {

	  route() {
		  return this.$route.path
	  },
  },

	mounted() {
	},
}
</script>

<style lang="scss" scoped>
  .main-wrapper {
    width: 100%;
    height: 100%;

    .main-wrapper__content {
      @include flexDirection();
      width: 100%;
      height: 100%;
    }

    .app-main {
      flex: 1;
      min-width: $max-width;
      height: calc(100% - 100px);
    }
  }
</style>
